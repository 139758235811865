const requestBtn = document.querySelector('.open-request-popup.green-btn');
const icon = requestBtn.querySelector('.icon');
const text = requestBtn.querySelector('.text');
let showingIcon = true;

function toggleElements() {
  requestBtn.classList.add('pulse');

  if (showingIcon) {
    icon.classList.remove('active');
    icon.classList.add('inactive');

    text.classList.remove('inactive');
    text.classList.add('active');
  } else {
    text.classList.remove('active');
    text.classList.add('inactive');

    icon.classList.remove('inactive');
    icon.classList.add('active');
  }

  setTimeout(() => {
    requestBtn.classList.remove('pulse');
  }, 1000);

  showingIcon = !showingIcon;
}

setInterval(toggleElements, 5000);

document.addEventListener('DOMContentLoaded', function () {
  var onlineChatButton = document.querySelector('.online-chat');
  var openRequestPopup = document.querySelector('.open-request-popup');
  var requestWidgetBox = document.querySelector('.request-widget-box');
  var chatIsOpen = false;
  var mobileCheckInterval;

  function openChat() {
    if (requestWidgetBox) {
      requestWidgetBox.classList.remove('active');
    }
    HelpCrunch('openChat');
    chatIsOpen = true;
    openRequestPopup.classList.add('close');

    if (isSmallScreen() && openRequestPopup.classList.contains('close')) {
      openRequestPopup.classList.remove('close');
    }

    if (isSmallScreen()) {
      mobileCheckInterval = setInterval(function () {
        if (!chatIsOpen) {
          clearInterval(mobileCheckInterval);
        } else {
          chatIsOpen = false;
        }
      }, 500);
    }
  }

  function closeChat() {
    HelpCrunch('closeChat');
    chatIsOpen = false;
    openRequestPopup.classList.remove('close');
  }

  if (onlineChatButton) {
    onlineChatButton.addEventListener('click', openChat);
  }

  if (openRequestPopup) {
    openRequestPopup.addEventListener('click', function () {
      if (chatIsOpen) {
        closeChat();
      } else if (openRequestPopup.classList.contains('green-btn')) {
        requestWidgetBox.classList.toggle('active');
        if (requestWidgetBox.classList.contains('active')) {
          openRequestPopup.classList.add('close');
        } else {
          openRequestPopup.classList.remove('close');
        }
      }
    });
  }

  var greenButtons = document.querySelectorAll('.green-btn:not(.open-request-popup)');
  greenButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      if (requestWidgetBox) {
        requestWidgetBox.classList.remove('active');
      }
      openRequestPopup.classList.remove('close');
    });
  });

  function isSmallScreen() {
    return window.innerWidth < 600;
  }
});
