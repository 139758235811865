import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import "./controllers"



require("./packs/slider")
require("./packs/header")
require("./packs/popup")
require("./packs/stages")
require("./packs/stage-animation.js")
require("./packs/request_form")
require("./packs/auto_open_form")
require("./packs/scroll")
require("./packs/phone-input")
require("./packs/search")
require("./packs/search_autocomplete")
require("./packs/green_btn_anim")
require("./packs/read_more")
