var startTime = parseInt(sessionStorage.getItem('startTime')) || new Date().getTime();
var popupShown = sessionStorage.getItem('popupShown') === 'true';

document.addEventListener('turbo:load', function () {
    var mainPopup = document.getElementById('main-popup');

    if (popupShown) {
        mainPopup.style.visibility = "hidden";
        document.body.style.overflow = 'auto';
        return;
    }
});

function updateTimer() {
    var currentTime = new Date().getTime();
    var elapsedTime = currentTime - startTime;

    sessionStorage.setItem('startTime', startTime.toString());

    var elapsedSeconds = Math.floor(elapsedTime / 1000);

    popupShown = sessionStorage.getItem('popupShown') === 'true';

    var mainPopup = document.getElementById('main-popup');


    if (!popupShown && elapsedSeconds >= 110) {
        mainPopup.style.visibility = "visible";
        document.body.style.overflow = 'hidden';
        sessionStorage.setItem('popupShown', 'true');
        popupShown = true;
    }
}


setInterval(updateTimer, 1000); 
