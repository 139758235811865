document.addEventListener("turbo:load", function () {
  const params = new URLSearchParams(window.location.search);
  const section = params.get("section");
  if (section) {
    const targetElement = document.querySelector(`#${section}`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }
});
